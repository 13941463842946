import React from "react";
import {Block} from "../atoms/Block";
import {IText} from "../../types/types";
import {IntroAndBody} from "../molecules/IntroAndBody";

interface IProps {
    entry: IText,
    last: boolean,
}

interface IState {
}

export class Text extends React.Component<IProps, IState> {

    render() {
        const {entry, last} = this.props;
        return (
            <Block last={last}>
                <IntroAndBody intro={entry.intro} body={entry.body} />
            </Block>
        );
    }

}