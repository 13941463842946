import React, {useEffect, useState} from "react";
import {IScreen} from "../../types/types"

export const ScreenContext = React.createContext<IScreen>({
    width: window.innerWidth || 1080,
    height: window.innerHeight || 1024
});

export const ScreenProvider: React.FC = ({children}) => {

    const getScreenSize = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }

    const handleResize = () => {
        setScreen(getScreenSize())
    }

    const [screen, setScreen] = useState<IScreen>(getScreenSize());

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    return (
        <ScreenContext.Provider value={screen}>
            {children}
        </ScreenContext.Provider>
    )
}