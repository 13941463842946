import React, {useContext, useEffect, useState} from "react";
import {scroller} from "react-scroll";
import {scrollerOptions} from "../../utils/scroller-options";
import {useLocation} from "react-router-dom";
import {ScreenContext} from "./WindowProvider";
import {headerHeight} from "../../utils/breakpoints";


interface IOffsetElement {
    index: number,
    height: number,
}

interface IScrollToElementOptions {
    name: string,
    index?: number,
    isMobileHeader?: boolean,
    cancelabel?: boolean,
}

interface IContext {
    scrollToElement: (options: IScrollToElementOptions) => void,
    setCurrentOffset: (nextOffset: IOffsetElement) => void,
    offset: IOffsetElement,
}

const defaultOffset: IOffsetElement = {
    index: -1,
    height: 0,
}

const defaultContext: IContext = {
    scrollToElement: (options) => {
    },
    setCurrentOffset: (nextOffset) => {
    },
    offset: defaultOffset,
}


export const ScrollContext = React.createContext(defaultContext);


export const ScrollProvider: React.FC = ({children}) => {


    const location = useLocation();
    const [offset, setOffset] = useState<IOffsetElement>(defaultOffset);
    const {width} = useContext(ScreenContext);

    useEffect(() => {
        if (location.pathname === "/") {
            setOffset(defaultOffset);
        }
    }, [location]);


    const setCurrentOffset = (
        nextOffset: IOffsetElement
    ) => {
        setOffset(nextOffset)
    }


    const scrollToElement = (
        options: IScrollToElementOptions
    ) => {

        /*
        *   calculate offset
        */
        let _off = 0;
        if (options.index !== undefined) {
            if (
                options.index > offset.index
            ) {
                _off = offset.height;
            }
        }

        _off += headerHeight();


        scroller.scrollTo(
            options.name,
            {
                ...scrollerOptions,
                offset: -_off,
                ignoreCancelEvents: options.cancelabel ? !options.cancelabel : scrollerOptions.ignoreCancelEvents,
            }
        )

    }


    return (
        <ScrollContext.Provider
            value={{
                scrollToElement,
                setCurrentOffset,
                offset: offset
            }}
        >
            {children}
        </ScrollContext.Provider>
    )
}
