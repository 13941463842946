import React, {ReactEventHandler} from "react";
import styles from "./HeaderRow.module.scss"

export const HeaderRow: React.FC<{
    handleClick?: ReactEventHandler,
}> = ({
          children,
          handleClick,
      }) => {
    return (
        <div
            className={`${styles.header} text-lg clickable`}
            onClick={handleClick}
        >
            {children}
        </div>
    )
}