import React from "react";
import styles from "./Logo.module.scss";
import {animateScroll} from "react-scroll/modules";
import {useHistory} from "react-router-dom";


export const Logo: React.FC = () => {

    const history = useHistory();

    return (
        <div className={styles.logo}>
            <a
                href={"/"}
                onClick={(e) => {
                    e.preventDefault()
                    animateScroll.scrollToTop()
                    history.push(
                        "/",
                        {home: true}
                    )
                }}
            >
                <span className={styles.shifted}>
                    Back Simonsen
                </span>
            </a>
        </div>
    )
}