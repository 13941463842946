import React from "react";
import styles from "./MoreBtn.module.scss"


export const MoreBtn:React.FC<{
    open: boolean,
    onClick?:any
}> = ({open, onClick}) => {
    return (
        <button
            className={`${styles.button} ${open?styles.open:""}`}
            onClick={onClick}
            type={"button"}
        >
            <div className={styles.p1} />
            <div className={styles.p2} />
            <div className={styles.p3} />
        </button>
    )
}