import React from "react";
import styles from "./LabelContent.module.scss";


export const LabelContent: React.FC<{
    onClick: (e: React.MouseEvent) => void
}> = ({
          children,
          onClick
      }) => {
    return (
        <div
            onClick={onClick}
            className={styles.wrapper}
        >
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}