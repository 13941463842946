import React, {useEffect, useRef, useState} from 'react';
import "./styles/index.scss";
import axios from "axios";
import {IData} from "./types/types";
import {Layout} from "./Layout";
import {ScreenProvider} from "./components/providers/WindowProvider";
import {ScrollProvider} from "./components/providers/ScrollProvider";
import {BrowserRouter as Router} from "react-router-dom";
import {Loader} from "./components/molecules/Loader";
import {CSSTransition} from "react-transition-group";
import {BaselineGrid} from "./components/atoms/BaselineGrid";

function App() {

    const [data, setData] = useState<null | IData>(null);
    const [status, setStatus] = useState<"loading" | "error" | "complete">("loading");

    const loaderRef = useRef(null);
    const appRef = useRef(null);

    useEffect(() => {
        axios.get("/api/v2/index.json")
            .then(
                resp => {
                    setData(resp.data)
                    setStatus("complete")
                }
            )
            .catch(
                err => {
                    setStatus("error");
                }
            );
    }, [])


    if (status === "error") {
        return <div>404</div>
    }
    return (
        <div>


            <CSSTransition
                in={(status === "loading")}
                timeout={700}
                classNames={"fade700"}
                mountOnEnter
                unmountOnExit
                nodeRef={loaderRef}
            >
                <div ref={loaderRef}>
                    <Loader/>
                </div>
            </CSSTransition>


            <CSSTransition
                in={!!data}
                timeout={700}
                classNames={"fade700"}
                mountOnEnter
                unmountOnExit
                nodeRef={appRef}
            >
                <div ref={appRef}>
                    {data &&
                    <Router>
                        <ScreenProvider>
                            <ScrollProvider>
                                <Layout data={data}/>
                            </ScrollProvider>
                        </ScreenProvider>
                    </Router>
                    }
                </div>
            </CSSTransition>


              {/*<BaselineGrid />*/}

        </div>
    )

}


export default App;
