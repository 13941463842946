import React from "react";
import {IGif, IImage} from "../../types/types";
import {GifView} from "./GifView";
import Image from "./Image";

interface IProps {
    image: IImage | IGif,
}

export const MediaView = ({image}: IProps) => {
    if (image.mimeType === "image/gif") {
        return <GifView {...image} />
    } else {
        return <Image {...image} />
    }
}