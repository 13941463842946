import React from "react";
import {Block} from "../atoms/Block";
import {IAccordion} from "../../types/types";
import {ScrollElementWrapper} from "../atoms/ScrollElementWrapper";
import {Link, Route} from "react-router-dom";
import styles from "../molecules/ProjectEntry.module.scss";
import {HeaderRow} from "../atoms/HeaderRow";
import {OffsetObserver} from "../molecules/OffsetObserver";
import {CollapsableContent} from "../atoms/CollapsableContent";
import {Redactor} from "../atoms/Redactor";
import {BodyRow} from "../atoms/BodyRow";

interface IProps {
    entry: IAccordion,
    last: boolean,
    lastSection: boolean,
}

interface IState {
}

export class Accordion extends React.Component<IProps, IState> {

    render() {

        const {entry, last, lastSection} = this.props;

        return (
            <Route
                path={`/${entry.uri}`}
                children={({match}) => (
                    <ScrollElementWrapper name={entry.slug}>
                        <Block last={last} fullHeight={last&&lastSection}>

                            <HeaderRow>
                                <Link
                                    to={!!match ? "/" : `/${entry.uri}`}
                                    className={styles.link}
                                >
                                    {entry.title}
                                </Link>
                            </HeaderRow>


                            <OffsetObserver
                                index={entry.index}
                                active={!!match}
                                name={entry.slug}
                                key={entry.slug}
                            >
                                <CollapsableContent
                                    open={!!match}
                                >
                                    <BodyRow>
                                        <Redactor
                                            htmlStr={entry.body}
                                        />
                                    </BodyRow>
                                </CollapsableContent>
                            </OffsetObserver>


                        </Block>
                    </ScrollElementWrapper>
                )}/>
        )
    }

}