import React from "react";
import styles from "./CollapsableContent.module.scss"
import Measure, {ContentRect} from "react-measure";


const transitionTimeout = 700;

interface IProps {
    open: boolean,
}

interface IState {
    status: "closed" | "opening_zero" | "opening_height" | "open" | "closing_height" | "closing_zero",
    moving: boolean,
    wait: boolean,
    height: undefined | number,
}

export class CollapsableContent extends React.Component<IProps, IState> {

    private animationTimer: any;
    private retardTimer: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            status: props.open ? "open" : "closed",
            moving: false,
            height: undefined,
            wait: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<any>, snapshot?: any) {
        const {open} = this.props;
        const {status, height, moving, wait} = this.state;

        // TODO: abbruch ermöglichen
        switch (status) {
            case "closed":
                if (open) {
                    this.setState({status: "opening_zero"});
                }
                break;
            case "opening_zero":
                if (height) {
                    this.setState({status: "opening_height", moving: true});
                    this.animationTimer = setTimeout(() => {
                        this.setState({moving: false})
                    }, transitionTimeout)
                }
                break;
            case "opening_height":
                if (!moving) {
                    this.setState({status: "open"});
                }
                break;
            case "open":
                if (!open && height !== undefined) {
                    this.setState({status: "closing_height", wait: true});
                    this.retardTimer = setTimeout(() => {
                        this.setState({wait: false});
                    }, 5);
                }
                break;
            case "closing_height":
                if (!wait) {
                    this.setState({status: "closing_zero", moving: true});
                    this.animationTimer = setTimeout(() => {
                        this.setState({moving: false})
                    }, transitionTimeout)
                }
                break;
            case "closing_zero":
                if (!moving) {
                    this.setState({status: "closed"});
                }
                break;
        }

    }

    componentWillUnmount() {
        clearTimeout(this.animationTimer);
        clearTimeout(this.retardTimer);
    }

    updateHeight(contentRect: ContentRect) {
        this.setState(prevState => {
            return {height: contentRect.bounds?.height}
        })
    }

    render() {
        const {children} = this.props;
        const {height, status} = this.state;

        let wrapperHeight;
        switch (status) {
            case "closed":
                wrapperHeight = `0`;
                break;
            case "opening_zero":
                wrapperHeight = `0`;
                break;
            case "opening_height":
                wrapperHeight = `${height}px`;
                break;
            case "open":
                wrapperHeight = `auto`;
                break;
            case "closing_height":
                wrapperHeight = `${height}px`;
                break;
            case "closing_zero":
                wrapperHeight = `0`;
                break;
        }
        if (height === undefined) {
            wrapperHeight = "auto";
        }

        const style = {
            height: wrapperHeight
        }

        return (
            <div className={`${styles.wrapper}`} style={style}>

                <Measure
                    bounds
                    onResize={this.updateHeight.bind(this)}
                >
                    {({measureRef}) =>
                        <div ref={measureRef}>
                            {status !== "closed" ? children : null}
                        </div>
                    }
                </Measure>

            </div>
        )
    }

}
