import React from "react";
import {Line} from "./Line";
import styles from "./LineContainer.module.scss";

export const LineContainer: React.FC<{
    labelClickHandler: (e: React.MouseEvent) => void,
    open: boolean,
}> = ({
          labelClickHandler,
          open
      }) => {

    return (
        <div className={`
            ${styles.container} 
            ${open ? styles.open : ""}
        `}>

            <div className={styles.track}>

                <div
                    className={`${styles.sledge}`}
                    onClick={labelClickHandler}
                >
                    <div className={`${styles.sledgeInner}`}>
                        <Line/>
                    </div>
                </div>

            </div>

        </div>
    )
}