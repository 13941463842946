import React, {useContext, useEffect, useState} from "react";
import {Section} from "../molecules/Section";
import {LabelContent} from "../atoms/LabelContent";
import {ScreenContext} from "../providers/WindowProvider";
import {useLocation, matchPath, useHistory} from "react-router-dom";
import {IContainer} from "../../types/types";
import {Entry} from "./Entry";
import {breakpoints} from "../../utils/breakpoints";

interface IProps {
    container: IContainer,
    last?: boolean,
}


export const Container: React.FC<IProps> =
    ({
         container,
         last
     }) => {


        const location = useLocation();
        const isMatch = !!matchPath(location.pathname, "/" + container.slug);
        const history = useHistory();

        const slugs = container.descendants.map(e => `/${e.slug}`);
        const match = matchPath(
            location.pathname,
            slugs
        )

        const {width} = useContext(ScreenContext);

        const mountOpen = (width < breakpoints.initialOpen) ? container.initialGeoeffnetMobile : container.initialGeoeffnetDesktop;
        const initialOpen = match ? true : mountOpen;

        const [open, setOpen] = useState<boolean>(initialOpen || isMatch);

        useEffect(() => {
            // @ts-ignore
            if (location?.state?.home) {
                setOpen(initialOpen)
            }
        }, [location])

        useEffect(() => {
            if (!open && isMatch) {
                // this container close
                // an entry inside this container is open
                history.push("/")
            }
        }, [open])


        const handleLabelClick = (e: React.MouseEvent) => {
            e.preventDefault();
            setOpen(!open);
        }

        return (
            <Section
                labelClickHandler={handleLabelClick}
                open={open}
                name={container.slug}
                last={last}
                label={
                    <LabelContent onClick={handleLabelClick}>
                        {container.title}
                    </LabelContent>
                }
            >
                {
                    container.descendants.map(
                        (entry, index) => {
                            return (
                                <Entry
                                    key={entry.slug}
                                    entry={entry}
                                    last={(container.descendants.length - 1 === index)}
                                    lastSection={!!last}
                                />
                            )
                        }
                    )
                }
            </Section>
        )
    }
;
