import React, {Component} from "react";
import 'swiper/swiper.scss';
import SwiperCore, {Keyboard} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {IProject} from "../../types/types";
import styles from "./Slider.module.scss";
import {MediaView} from "../atoms/MediaView";


SwiperCore.use([Keyboard]);


type IProps = {
    project: IProject,
    updateIndex: (index: number) => void
}

interface IState {
    swiperInstance: any
}


class Slider extends Component<IProps, IState> {


    constructor(props: IProps) {
        super(props);
        this.state = {swiperInstance: null}
    }

    render() {
        const {project, updateIndex} = this.props;

        if (project.slides.length === 1) {
            return <MediaView image={project.slides[0].image}/>
        } else {
            return (
                <Swiper
                    preloadImages={false}
                    speed={650}
                    slidesPerView={1}
                    spaceBetween={1024}
                    loop={true}
                    keyboard={true}
                    initialSlide={0}
                    onSwiper={(swiper) => {
                        this.setState({swiperInstance: swiper})
                    }}
                    style={{width: '100%', height: '100%'}}
                    onRealIndexChange={(args) => {
                        updateIndex(args.realIndex)
                    }}
                >
                    {
                        project.slides.map(
                            (slide, i) => (
                                <SwiperSlide key={slide.id}>
                                    <div className={styles.slide}>
                                        <div
                                            className={styles.nextBtn}
                                            onClick={() => {
                                                if (this.state.swiperInstance) {
                                                    this.state.swiperInstance.slideNext()
                                                }
                                            }}
                                        />
                                        <div
                                            className={styles.prevBtn}
                                            onClick={() => {
                                                if (this.state.swiperInstance) {
                                                    this.state.swiperInstance.slidePrev()
                                                }
                                            }}
                                        />
                                        <MediaView image={slide.image}/>
                                    </div>
                                </SwiperSlide>
                            )
                        )
                    }
                </Swiper>
            );
        }
    }
}

export default Slider;