import React, {useEffect, useRef} from "react";
import styles from "./Redactor.module.scss";
import {useHistory} from "react-router-dom";

export const Redactor: React.FC<{
    htmlStr: string | null,
}> = ({htmlStr}) => {

    const history = useHistory()
    const ref = useRef<HTMLDivElement>(null);

    // programmatically navigate to href on anchor tag click
    const navigateToTargetHref = (e: MouseEvent) => {
        // extract href attribute
        // @ts-ignore
        const href = e.target?.getAttribute("href");
        // make sure the href const is a string
        if(typeof href !== "string"){
            return;
        }
        // check if the href starts with /a,ä,u
        const isRelativeLink = /^\/[\w|\%]/.test(href);
        if(isRelativeLink){
            e.preventDefault();
            history.push(href)
        }
    }

    // add click event listeners to all the anchor tags inside of the redactor html
    useEffect(() => {
        const currentHtml = ref.current;
        currentHtml?.querySelectorAll('a').forEach((a) => {
            a.addEventListener("click", navigateToTargetHref);
        })
        return () => {
            currentHtml?.querySelectorAll('a').forEach((a) => {
                a.removeEventListener("click", navigateToTargetHref);
            })
        }
    }, [ref])


    if (htmlStr) {
        return (
            <div
                dangerouslySetInnerHTML={{__html: htmlStr ? htmlStr : ""}}
                className={`
                    ${styles.redactor} 
                `}
                ref={ref}
            />
        );
    } else {
        return null;
    }

}

