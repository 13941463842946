import React from "react";
import {IAccordion, IProject, IText} from "../../types/types";
import {Accordion} from "../entry-types/Accordion";
import {Project} from "../entry-types/Project";
import {Text} from "../entry-types/Text";

export const Entry: React.FC<{
    entry: IAccordion | IText | IProject,
    last: boolean,
    lastSection: boolean,
}> = ({
          entry,
          last,
          lastSection
      }) => {

    switch (entry.type) {
        case "accordion":
            return <Accordion entry={entry} last={last} lastSection={lastSection}/>;
        case "project":
            return <Project entry={entry} last={last}/>;
        case "text":
            return <Text entry={entry} last={last}/>;
        default:
            return null;
    }

}