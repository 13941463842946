import {convertRemToPixels} from "./convertRemToPx";

export const breakpoints = {
    sm: 430,
    md: 1000,
    lg: 1220,
    xl: 1290,
    xxl: 2253,
    initialOpen: 500,
}

export const isMobile = (w:number)=>{
    return w < breakpoints.lg;
}

export const headerHeight = () => {
    if (isMobile(window.innerWidth)) {
        return convertRemToPixels(7.8);
    } else {
        return 0;
    }
}

export const logoHeight = () => {
    if (isMobile(window.innerWidth)) {
        return convertRemToPixels(4.083);
    } else {
        return 0;
    }
}