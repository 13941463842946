import React, {ReactEventHandler, useEffect, useRef, useState} from "react";
import styles from "./Image.module.scss";
import {IImage} from "../../types/types";


const Image: React.FC<IImage & {
    factor?: number,
    noIntro?: boolean,
    onLoad?: ReactEventHandler,
    additionalClasses?: string,
}> = (
    {
        factor = 1,
        noIntro,
        url_sm,
        url_md,
        url_lg,
        width,
        height,
        title,
        onLoad,
        additionalClasses = "",
    }
) => {


    const [isLoading, setIsLoading] = useState(!noIntro);

    const imgRef = useRef<HTMLImageElement | null>(null);

    const src = url_sm;
    const srcSet = `${url_sm} 768w, ${url_md} 1440w, ${url_lg} 2560w`;
    const sizes = `(min-width: 2560px) ${2560 * factor}px, (min-width: 1440px) ${1440 * factor}px, ${100 * factor}vw`;

    const handleLoad: ReactEventHandler = (e) => {
        setIsLoading(false);
        if (onLoad) onLoad(e);
    }

    useEffect(() => {
        if (imgRef.current) {
            if (!imgRef.current.complete && imgRef.current.naturalHeight !== 0) {
                setIsLoading(true);
            }
        }
    }, [])


    return (
        <img
            ref={imgRef}
            src={src}
            srcSet={srcSet}
            sizes={sizes}
            alt={title}
            width={width}
            height={height}
            className={`
                ${styles.image} 
                ${styles.introAnimation} 
                ${isLoading ? styles.isLoading : ""} 
                ${additionalClasses} 
            `}
            onLoad={handleLoad}
        />
    )

}

export default Image;