import React from "react";
import {Block} from "../atoms/Block";
import {IProject} from "../../types/types";
import {ScrollElementWrapper} from "../atoms/ScrollElementWrapper";
import {Link, Route, RouteChildrenProps} from "react-router-dom";
import {HeaderRow} from "../atoms/HeaderRow";
import styles from "../molecules/ProjectEntry.module.scss";
import {OffsetObserver} from "../molecules/OffsetObserver";
import {CollapsableContent} from "../atoms/CollapsableContent";
import {ProjectBody} from "../molecules/ProjectBody";

interface IProps {
    entry: IProject,
    last: boolean,
}

interface IState {
}

type IPropsWithRoute = RouteChildrenProps & IProps;

class ProjectWithRoute extends React.Component<IPropsWithRoute, IState> {

    render() {
        const {entry, match, last} = this.props;
        return (
            <ScrollElementWrapper name={entry.slug}>
                <Block last={last}>


                    <HeaderRow>
                        <Link
                            to={!!match ? "/" : `/${entry.uri}`}
                            className={styles.link}
                        >
                            {entry.title}
                        </Link>
                    </HeaderRow>


                    <OffsetObserver
                        index={entry.index}
                        active={!!match}
                        name={entry.slug}
                    >
                        <CollapsableContent
                            open={!!match}
                        >
                            <ProjectBody
                                key={entry.slug}
                                projectNr={entry.index}
                                project={entry}
                                open={!!match}
                            />
                        </CollapsableContent>
                    </OffsetObserver>


                </Block>
            </ScrollElementWrapper>
        )
    }
}


export const Project = (props: IProps) => (
    <Route
        path={`/${props.entry.uri}`}
        children={
            (route) =>
                <ProjectWithRoute {...props} {...route} />
        }
    />
)