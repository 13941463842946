import React from "react";
import {IContainer} from "../../types/types";
import {Container} from "./Container";

interface IProps {
    containers: IContainer[];
}

interface IState {
}

export class ContainerList extends React.Component<IProps, IState> {

    render() {

        const {containers} = this.props;

        return (
            <div>
                {
                    containers.map(
                        (container, index) =>
                            <Container
                                key={container.slug}
                                container={container}
                                last={index + 1 === containers.length}
                            />
                    )
                }
            </div>
        )
    }

}