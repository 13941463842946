import styles from "./GifView.module.scss"
import {IGif} from "../../types/types";

export const GifView: React.FC<IGif> =
    ({
         url, title, width, height
     }) => {
        return (
            <img
                src={url}
                alt={title}
                width={width}
                height={height}
                className={styles.image}
            />
        )
    }