import React, {ReactNode, useState} from "react";
import {Redactor} from "../atoms/Redactor";
import {CollapseCallbackArgs, UnmountClosed} from "react-collapse";
import styles from "./IntroAndBody.module.scss"
import {MoreBtn} from "../atoms/MoreBtn";

interface IProps {
    intro: string | null,
    body: ReactNode | null,
    considerChange?: boolean,
    workCallback?: (args: CollapseCallbackArgs) => void,
    restCallback?: (args: CollapseCallbackArgs) => void,
}

export const IntroAndBody: React.FC<IProps> =
    ({
         intro,
         body,
         workCallback,
         restCallback,
     }) => {

        const [open, setOpen] = useState(false);


        return (
            <div className={styles.wrapper}>

                <div className={styles.intro}>
                    <Redactor
                        htmlStr={intro}
                    />
                </div>

                {
                    body &&
                    <div>

                        <UnmountClosed
                            isOpened={open}
                            onWork={workCallback ? workCallback : undefined}
                            onRest={restCallback ? restCallback : undefined}
                        >
                            <div className={styles.body}>
                                <Redactor
                                    // @ts-ignore
                                    htmlStr={body}
                                />
                            </div>
                        </UnmountClosed>
                        <MoreBtn
                            open={open}
                            onClick={() => setOpen(!open)}
                        />

                    </div>
                }

            </div>
        )
    }