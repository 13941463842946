import React from "react";
import styles from "./Block.module.scss"

export const Block: React.FC<{
    last?: boolean,
    fullHeight?: boolean,
}> = ({
          children,
          last,
          fullHeight
      }) => {
    return (
        <div
            className={`
                ${styles.block} 
                ${last ? styles.last : ""}
                ${fullHeight ? styles.fullHeight : ""}
            `}
        >
            {children}
        </div>
    )
}