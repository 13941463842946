import React from "react";
import {Element} from "react-scroll";


export const ScrollElementWrapper: React.FC<{
    name?: string,
    index?: number,
}> = ({children, name}) => {

    if (name) {

        return (
            <Element name={name}>
                {children}
            </Element>
        )

    } else {

        return (
            <div>
                {children}
            </div>
        )

    }

}