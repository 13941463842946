import React, {useContext, useEffect, useState} from "react";
import Measure, {ContentRect} from "react-measure";
import {ScrollContext} from "../providers/ScrollProvider";
import {isMobile} from "../../utils/breakpoints";
import {ScreenContext} from "../providers/WindowProvider";

export const OffsetObserver: React.FC<{
    index: number,
    active: boolean,
    name: string,
    isMobileHeader?: boolean,
}> = ({
          children,
          index,
          active,
          name,
          isMobileHeader
      }) => {


    const [initial, setInitial] = useState(true);

    /*
    *       if this project is active,
    *       then write the current offset to scroll context
    */

    const {
        scrollToElement,
        setCurrentOffset
    } = useContext(ScrollContext);

    const {width} = useContext(ScreenContext)


    const scrollIntoView = (name: string, index: number, isMobileHeader?: boolean) => {
        scrollToElement({
            name,
            index,
            isMobileHeader
        });
    }

    useEffect(() => {
        if (active) {

            if (initial) {
                setTimeout(() => {
                    scrollIntoView(
                        name,
                        index,
                        isMobileHeader
                    );
                }, 500);
            } else {
                scrollIntoView(
                    name,
                    index,
                    isMobileHeader
                );
            }

        }
    }, [active])

    useEffect(() => {
        setInitial(false)
    }, [])


    const handleResize = (contentRect: ContentRect) => {
        /*
        *   add wired offset for impressum and datenschutz.
        */
        let off = 0;
        if ((name === "impressum" || name === "datenschutz") && !isMobile(width)) {
            off = 39;
        }
        if (
            active &&
            contentRect?.entry?.height
        ) {
            setCurrentOffset({
                index: index,
                height: contentRect.entry.height - off,
            })
        }
    }

    return (
        <Measure onResize={handleResize}>
            {({measureRef}) => (
                <div ref={measureRef}>
                    {children}
                </div>
            )}
        </Measure>
    )
}