import React, {Fragment, useContext, useState} from "react";
import {IProject} from "../../types/types";
import styles from "./ProjectMedia.module.scss"
import Slider from "./Slider";
import {SwiperCaption} from "../atoms/SwiperCaption";
import {ScrollContext} from "../providers/ScrollProvider";


export const ProjectMedia: React.FC<{
    project: IProject,
}> = ({
          project,
      }) => {

    const {scrollToElement} = useContext(ScrollContext);
    const [currentIndex, setCurrentIndex] = useState(0)

    const updateCurrent = (index: number) => {
        scrollToElement({name: project.slug,cancelabel:true})
        setCurrentIndex(index)
    }

    if (!project.slides.length) {
        return null;
    }


    return (
        <Fragment>

            <div
                className={styles.wrapper}
            >
                <div className={styles.inner}>
                    <Slider
                        project={project}
                        updateIndex={updateCurrent}
                    />
                </div>
            </div>

            <SwiperCaption
                current={currentIndex}
                total={project.slides.length}
                caption={project.slides[currentIndex]?.caption}
            />

        </Fragment>
    )
}