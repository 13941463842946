import React from "react";
import styles from "./SwiperCaption.module.scss"
import {zeroFill} from "../../utils/zeroFill";

export const SwiperCaption: React.FC<{
    current: number,
    total: number,
    caption: string
}> = ({current, total, caption}) => {
    return (
        <div className={styles.wrapper}>

            <div className={styles.pagination}>
                {zeroFill((current + 1).toString(), total.toString().length)} / {total}
            </div>

            <div className={styles.caption}>
                {caption}
            </div>

        </div>
    )
}