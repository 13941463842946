import React, {useRef} from "react";
import styles from "./Layout.module.scss";
import {IData} from "./types/types";

import {Logo} from "./components/atoms/Logo";
import {ContainerList} from "./components/organisms/ContainerList";


export const Layout: React.FC<{ data: IData }> = ({data}) => {

    const appRef = useRef<HTMLDivElement>(null)



    return (
        <div className={styles.app} ref={appRef}>
            <div className={styles.container}>

                <header className={styles.header}>
                    <Logo/>
                </header>

                <main className={styles.main}>
                    <ContainerList containers={data.contents}/>
                </main>

            </div>
        </div>
    )
}