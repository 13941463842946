import React, {useEffect, useRef, useState} from "react";
import styles from "./Section.module.scss";
import {Line} from "../atoms/Line";
import {ScrollElementWrapper} from "../atoms/ScrollElementWrapper";
import {animateScroll as scroll} from 'react-scroll'
import {scrollerOptions} from "../../utils/scroller-options";
import {logoHeight} from "../../utils/breakpoints";
import {LineContainer} from "../atoms/LineContainer";
import {CollapsableContent} from "../atoms/CollapsableContent";


export const Section: React.FC<{
    label: React.ReactNode,
    labelClickHandler: (e: React.MouseEvent) => void,
    name: string,
    open: boolean,
    last?: boolean,
}> = ({
          children,
          label,
          labelClickHandler,
          name,
          open,
          last
      }) => {

    /***
     *      scroll page up if the closing section has overflows on top
     */
    const [cachedOpen, setCachedOpen] = useState(open);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (open !== cachedOpen) {
            setCachedOpen(open);
            if (!open) {
                let offsetTop = ref.current?.getBoundingClientRect().y
                if (offsetTop) {
                    offsetTop -= logoHeight();
                    if (offsetTop < 0) {
                        scroll.scrollMore(offsetTop, {...scrollerOptions, duration: 400});
                    }
                }
            }
        }
    }, [open])

    return (
        <ScrollElementWrapper name={name}>
            <div
                className={`
                    ${styles.wrapper} 
                    ${last ? styles.last : ""}
                    ${open ? styles.open : ""}
                `}
                ref={ref}
            >
                <div className={`${styles.section}`}>


                    <LineContainer
                        labelClickHandler={labelClickHandler}
                        open={open}
                    />


                    <div className={`${styles.label}`}>
                        <div className={styles.labelContent}>
                            {label}
                            <div className={styles.lineMobile} onClick={labelClickHandler}>
                                <Line/>
                            </div>
                        </div>
                    </div>


                    <div className={`${styles.content}`}>
                        <CollapsableContent open={open}>
                            <div className={styles.body}>
                                {children}
                            </div>
                        </CollapsableContent>
                    </div>


                </div>
            </div>
        </ScrollElementWrapper>
    )
}