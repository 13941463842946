import React, {Fragment, useRef, useState} from "react";
import {IProject} from "../../types/types";
import styles from "./ProjectBody.module.scss";
import {Redactor} from "../atoms/Redactor";
import {MoreBtn} from "../atoms/MoreBtn";
import {animateScroll} from "react-scroll/modules";
import {scrollerOptions} from "../../utils/scroller-options";
import {CollapsableContent} from "../atoms/CollapsableContent";
import {ProjectMedia} from "./ProjectMedia";


export const ProjectBody: React.FC<{
    project: IProject,
    projectNr: number,
    open: boolean,
}> = ({project}) => {


    const [extended, setExtended] = useState(false);
    const extensionRef = useRef<HTMLDivElement>(null);

    const toggleExtension = () => {
        setExtended(!extended)
        if (extended) {
            scrollExtensionUp()
        }
    }

    const scrollExtensionUp = () => {
        const distanceToScroll = extensionRef.current?.clientHeight;
        if (distanceToScroll) {
            animateScroll.scrollMore(-distanceToScroll, {...scrollerOptions, duration: 600});
        }
    }

    return (
        <div className={styles.wrapper}>

            {
                project.slides.length &&
                <ProjectMedia
                    project={project}
                />
            }

            <Redactor htmlStr={project.intro}/>

            {
                (project.body || project.credits) &&
                <Fragment>

                    <CollapsableContent
                        open={extended}
                        key={project.slug}
                    >
                        <div ref={extensionRef}>

                            <div className={styles.body}>
                                <Redactor htmlStr={project.body}/>
                            </div>
                            {
                                project.credits &&
                                <div className={styles.credits}>
                                    <Redactor htmlStr={project.credits}/>
                                </div>
                            }

                        </div>
                    </CollapsableContent>

                    <MoreBtn
                        open={extended}
                        onClick={toggleExtension}
                    />

                </Fragment>
            }

        </div>
    )

}
